<template>
    <div>
        <Quote/>
        <GoBack next="Kursni tahrirlash"/>
        <div class="row justify-content-center">
            <div class="col-11 col-sm-10 col-md-8">
                <form class="ABC" @submit.prevent="editModule">
                    <div class="mb-3">
                        <label for="exampleInputId4" class="form-label">
                            Kurs nomini tahrirlang
                        </label>
                        <input
                            type="text"
                            class="form-control"
                            id="exampleInputId4"
                            v-model="module.name"
                            required
                        >
                    </div>

                    <div class="mb-3">
                        <label for="exampleInputId5" class="form-label">
                            Kurs chegirmasini tahrirlang
                        </label>
                        <input
                            type="number"
                            class="form-control"
                            id="exampleInputId5"
                            v-model="module.discountPercent"
                        >
                    </div>
                    <div class="row justify-content-end mb-3">
                        <div class="col-4 text-end">
                            <button-component>O'zgartirish</button-component>
                        </div>
                    </div>
                    <div>
                        <b-modal ref="modal-success" hide-footer title="Kadirov.Dev">
                            <div class="d-block text-center">
                                <h3>Kurs muvaffaqiyatli o'zgartirildi</h3>
                            </div>
                            <b-button
                                class="mt-3 float-end"
                                style="width: 100px"
                                variant="btn btn-primary"
                                size="md"
                                @click="hideErrorModal"
                            >
                                OK
                            </b-button>
                        </b-modal>
                    </div>
                </form>
            </div>
        </div>
        <b-overlay :show="show" no-wrap>
        </b-overlay>
    </div>
</template>

<script>
import Quote from "@/components/Quote";
import GoBack from "@/components/GoBack";
import accessMixin from "@/mixin/accessMixin";
import {mapActions, mapGetters} from "vuex";
import ButtonComponent from "@/components/ButtonComponent.vue";

export default {
    name: "Add-CoursePage",
    mixins: [accessMixin],
    components: {ButtonComponent, GoBack, Quote},
    data() {
        return {
            module: {
                id: null,
                name: '',
                discountPercent: null
            },
            show: true,
        }
    },
    computed: {
        ...mapGetters(['getModule'])
    },
    methods: {
        ...mapActions([
            'fetchAddModule',
            'fetchModule',
            'putModule'
        ]),
        editModule() {
            let data = {
                id: this.$route.params.id,
                name: this.module.name,
                discountPercent: parseInt(this.module.discountPercent)
            }
            this.putModule(data)
                .then(() => {
                    this.$refs['modal-success'].show()
                    setTimeout(this.hideErrorModal, 3000)
                })
                .catch(() => {
                    console.log('addModule da xato')
                })
        },
        hideErrorModal() {
            this.$refs['modal-success'].hide()
            this.$router.push('/courses')
        }

    },
    mounted() {
        this.show = true
        this.fetchModule(this.$route.params.id)
            .then(() => {
                this.module.id = this.getModule.id
                this.module.name = this.getModule.name
                this.module.discountPercent = this.getModule.discountPercent
            })
        this.show = false
    }
}
</script>

<style scoped>

* {
    padding: 0;
    margin: 0;
}

select {
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    background-image: url("../../public/img/select.svg");
    background-repeat: no-repeat;
    background-size: 25px;
}

select::-ms-expand {
    display: none !important;
}

section form {
    font-family: Comfortaa, cursive;
    font-size: 18px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px;
    text-align: left;
    margin-top: 100px;
    margin-bottom: 100px;
}

.mb-3 .form-control {
    border-radius: 10px;
    border: solid #80007F 1px;
    font-family: Comfortaa, cursive;
}

.form-control:focus {
    box-shadow: 0 0.5px 0.5px #FFFFFF inset, 0 0 5px #b505b4 !important;
}

.form-control:hover {
    box-shadow: 0 0.5px 0.5px #FFFFFF inset, 0 0 5px #b505b4 !important;
}

.justify-content-end .btn-primary {
    padding-left: 40px;
    padding-right: 40px;
    border-radius: 10px;
    background-color: #80007F;
    font-family: Comfortaa, cursive;
    font-size: 16px;
}

.btn-primary {
    color: #fff;
    background-color: #80007F;
    border-color: #80007F;
    padding-top: 8px;
    padding-bottom: 8px;


}

.form-control {
    color: #000000;
    padding-left: 1.5rem;
}

.ABC {
    margin-top: 100px;
    margin-bottom: 100px;
}
</style>
